import { createContext, useEffect, useState, ReactNode } from 'react';
import { useRouter } from 'next/router';
import { defaultLocale, isLocale } from './Dictionary';
import { axiosHeadersUpdater } from 'libs';

export interface Props {
  lang: string;
  setDefaultLanguage: React.Dispatch<React.SetStateAction<string>>;
}

interface Alternative {
  title: string;
  // link: string;
}

interface ProviderProps {
  children: ReactNode;
}

const defaultValue = {
  lang: defaultLocale,
  setDefaultLanguage: () => {},
};

export const LanguageContext = createContext<Props>(defaultValue);

const LanguageProvider = ({ children }: ProviderProps) => {
  const { query } = useRouter();
  const [defaultLanguage, setDefaultLanguage] = useState(
    isLocale(query?.lang as string) ? query.lang.toString() : defaultLocale,
  );
  axiosHeadersUpdater(defaultLanguage);

  useEffect(() => {
    axiosHeadersUpdater(defaultLanguage);
  }, [defaultLanguage]);

  return (
    <LanguageContext.Provider
      value={{
        lang: defaultLanguage,
        setDefaultLanguage,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageProvider;
